import React, { Fragment } from 'react';
import Helmet from "react-helmet";
import { websites } from './data';

import Hero from "../Hero"
import CTA from "../CTA"

const Website = ({page, dimensions}) => {

    return (
        <Fragment>
            <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Explore our website solution projects for startups and enterprises - Vary Digital</title>
        <meta name="title" content="Explore our website solution projects for startups and enterprises - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="Discover the online digital experiences we have built for our clients. Get in touch to discuss your project or idea with Vary Digital." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Explore our website solution projects for startups and enterprises - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="Discover the online digital experiences we have built for our clients. Get in touch to discuss your project or idea with Vary Digital." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-projects.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/websites" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="Explore our website solution projects for startups and enterprises - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="Discover the online digital experiences we have built for our clients. Get in touch to discuss your project or idea with Vary Digital." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-projects.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="Explore our website solution projects for startups and enterprises - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="Discover the online digital experiences we have built for our clients. Get in touch to discuss your project or idea with Vary Digital." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-projects.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/websites" data-react-helmet="true" />
      </Helmet>
        <Hero title="Our work" summary="Here are a selection of projects we have proudly produced." image="images/projects-hero.webp" />
        <section className="intro section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container container--flex">
                <header className="section-header">
                    <span className="section-subtitle">Explore our projects</span>
                    <div className="section-header-row">
                    <h2 className="section-title">Our projects are fueled by customer data and the results speak for themselves.</h2>
                    </div>
                </header>
            </div>
          </section>
        <section className={`section latest latest--${page}`}>
            <div className="container container--full">
                <div className="grid grid--websites">
                    {websites.map((website, websiteIndex) => {
                    //{filteredData.filter(website => website.teambuild === category).map((website, websiteIndex) => {

                        return (
                            <div className={`card card--website card--large`} key={websiteIndex}>
                                {(website.video ?
                                    (<video autoPlay loop muted className="hero__video" poster="">
                                        <source src={`videos/${website.codename}.webm`} type="video/webm" />
                                        <source src={`videos/${website.codename}.mp4`} type="video/mp4" />
                                    </video>)
                                    :
                                    (<img src={`/images/websites/${website.codename}.webp`} className={website.screenshot} alt={website.name} />)
                                )}
                                <div className="website__content">
                                    <span className="website__name">{website.name}</span>
                                    <h2 className="website__subtitle">{website.subtitle}</h2>
                                    <span className="website__summary">{website.summary}</span>
                                    <span className="card__arrow"></span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
        <CTA title="Do you have a project you would like to discuss?" />
        </Fragment>
    )
}

export default Website;