import { createGlobalStyle  } from 'styled-components';
import { device } from './components/Breakpoints';

export const GlobalStyles  = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  #root {
    width: 100%;
    overflow: ${({ open }) => open ? 'hidden' : 'auto'}
  }
  body {
    background: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
    display: flex;
    @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
    font-family: 'Raleway', sans-serif;
    text-rendering: optimizeLegibility;
  }

  .header {
      position: fixed;
      z-index: 4;
      display: flex;
      align-items: center;
      width: 100%;
      height: 72px;
      background-color: transparent;
      transition: background-color 1s ease;

      @media ${device.md} {
        height: 120px;
      }

      &.bg-black {
        background-color: var(--color-black);
      }
  }

  .footer {
    color: white;
    font-size: .875rem;
  }

  .skew {
    position: relative;
    top: auto;
    bottom: 20%;
    height: 0;
    width: 0;

    &::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
      transform: rotate(-16deg);
      width: 300vw;
      height: 300vw;
      margin-left: -150vw;
    }
  }

  .grid {
    display: flex;
    display: grid;
  }
  `