import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { StyledMainNavigation } from './MainNavigation.styled';

const MainNavigation = () => {

  return (
    <StyledMainNavigation>
      <nav className="main-navigation">
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/about'>About</Link></li>
          <li><Link to='/websites'>Projects</Link></li>
          <li><Link to='/services'>Services</Link></li>
          <li className="boxed"><Link to='/contact'>Get in touch</Link></li>
        </ul>
      </nav>
    </StyledMainNavigation>
  )
}

export default MainNavigation;