import styled from 'styled-components';
import { device } from '../Breakpoints';

export const StyledMainNavigation = styled.nav`
  display: none;

  @media ${device.lg} {
    display: flex;
  }

  ul {
    display: flex;
    align-items: center;
    color: white;

    li {
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: 500;

        &:hover {
          color: var(--color-orange);
          text-decoration: none;
  
          &::after {
              width: 100%;
          }
  
          svg {
              width: 2rem;
          }
        }

        &.boxed {

            &:hover {

              a {
                background-color: var(--color-orange);
                color: white;
              }
            }

            &::after {
              display: none;
            }

            a {
              background-color: white;
              color: var(--color-black);
              padding: .75rem var(--base-padding);
            }
        }

        &::after {
          display: block;
          content: "";
          width: 0;
          height: 1px;
          background-color: var(--color-orange);
          transition: width 0.3s ease;
      }

        a {
          display: inline-block;
          width: auto;
          text-decoration: none;
          padding: .75rem;
          transition: background-color 0.3s ease;
          
          svg {
              fill: var(--color-orange);
              width: 1.25rem;
              height: 1.25rem;
              transition: width 0.3s ease;
          }
      }
    }
  }
`;