import React from 'react';

import Video from "../Video"


const NotFound = () => {

    return (
        <Video 
          mp4="videos/smoke.mp4"
          webm="videos/smoke.webm"
          title="Sorry, we can't find what you're looking for!"
          subtitle="Page not found"
            />
    )
}

export default NotFound;