export const recommendations = [
    {
        name: "Luke Stone",
        codename: "luke",
        title: "Managing Director",
        company: "Dental Engineers",
        recommendation: "We have had some many compliments on the logo, I am so pleased with it. We have had lots of sales through the website and that is all thanks to Vary Digital and their hard work.",
        stars: "5"
    },
    {
        name: "Sean Cooper",
        codename: "sean",
        title: "Owner",
        company: "Bolnore Heating Services",
        recommendation: "Brilliant, Vary makes website data very easy for me to see and understand. The website is getting so many comments (all good) from customers.",
        stars: "5"
    },
    {
        name: "Lynn Wilson",
        codename: "lynn",
        title: "Owner",
        company: "The Cock Inn",
        recommendation: "Vary Digital have provided an absolutely fantastic website, we have had lots of compliments from our customers and we are very happy with it.",
        stars: "5"
    },
    {
        name: "Bernie Hayes",
        codename: "bernie",
        title: "Owner",
        company: "Purple Hayes Creations",
        recommendation: "Vary Digital created an outstanding logo, brand and complete website for my company and really captured the look I was after.",
        stars: "5"
    },
    {
        name: "Alan Harris",
        codename: "alan",
        title: "Owner",
        company: "Jackal Home Improvements",
        recommendation: "Absolutely nailed the brief I supplied, high quality work and very cost effective, I highly recommend!",
        stars: "5"
    }
];