import React, { Fragment } from 'react';
import Hero from "../Hero"
import CTA from "../CTA"
import emailjs from 'emailjs-com';
import Helmet from "react-helmet";

const Contact = ({websites, dimensions}) => {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_klse4zt', 'template_bxa4qjv', e.target, 'user_Qsb8fwt2C6z6lQNznC6na')
      .then((result) => {
          console.log(result.text);

          if(result.text === "OK"){
            document.querySelector("form").style.display = 'none';
            document.querySelector(".alert--success").style.display = 'block';
          }
      }, (error) => {
          console.log(error.text);
      });

  }

    return (
        <Fragment>
          <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Get in touch about a project or idea - Vary Digital</title>
        <meta name="title" content="Get in touch about a project or idea - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="If you are looking for a WordPress or WooCommerce digital agency, get in touch today, whether it's immediate help or advice we are here to help." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Get in touch about a project or idea - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="If you are looking for a WordPress or WooCommerce digital agency, get in touch today, whether it's immediate help or advice we are here to help." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-contact.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/contact" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="Get in touch about a project or idea - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="If you are looking for a WordPress or WooCommerce digital agency, get in touch today, whether it's immediate help or advice we are here to help." />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-contact.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="Get in touch about a project or idea - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="If you are looking for a WordPress or WooCommerce digital agency, get in touch today, whether it's immediate help or advice we are here to help." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-contact.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/contact" data-react-helmet="true" />
      </Helmet>
        <Hero title="Get in touch" summary="Thank you for deciding to get in touch, we’d love to hear from you." image="images/contact-hero1.webp" />
        <section className="cta section--padding">
            <div className="container">
                <header className="section-header">
                    <span className="section-subtitle">Get in touch</span>
                    <div className="section-header-row">
                    <h2 className="section-title">We'd love to help bring your passion online. Let's start something.</h2>
                    </div>
                </header>
                <div className="grid grid--two grid-gap-8">
                  <div className="contact-details">
                    <p>If you are looking for a WordPress or WooCommerce digital agency, get in touch today, whether it's immediate help or advice we are here to help.</p>
                  <p>Our projects are fueled by customer data and the results speak for themselves.</p>
                  <p>

Telephone: <br />
<a className="link" href="tel:07512564193">075125 64193</a><br /><br />
Email:<br />
<a className="link" href="mailto:info@varydigital.com">info@varydigital.com</a></p>
                  </div>
                  <div>
                    <div className="alert alert--success" style={{display: 'none'}}>
                        Thank you for getting in touch, we will be in touch shortly.
                    </div>
        <form className="contact-form" onSubmit={sendEmail}>
        <div className="control-group half--input half--input-left">
      <input type="text" name="user_name" placeholder="Name" required />
      </div>
      <div className="control-group half--input half--input-right">
      <input type="company" name="company" placeholder="Company" />
      </div>
      <div className="control-group half--input half--input-left">
      <input type="email" name="user_email" placeholder="Email address" required />
      </div>
      <div className="control-group half--input half--input-right">
      <input type="number" name="contact_number" placeholder="Phone number" />
      </div>
      <div className="control-group">
      <textarea name="message" placeholder="About your project" />
      </div>
      <input className="button button--orange" type="submit" value="Send" />
    </form></div></div>
            </div>
        </section>
        <CTA title="Looking for a partner who values your success?" />
        </Fragment>
    )
}

export default Contact;