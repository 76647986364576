import styled from 'styled-components';
import { device } from '../Breakpoints';

export const StyledVideo = styled.section`
    min-height: 95vh;
    height: 55vh;
    width: 100%;
    overflow: hidden;
    text-align: left;
    padding-top: 120px;
    display: flex;
    align-items: center;
    
    @media (max-width: 768px) {
        min-height: 90vh;
        padding-top: 0;
    }

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 0;
        opacity: .2;
    }
    
    .container--flex {
        flex-direction: column;
        z-index: 1;
    }
    @media ${device.lg} {
        .container--flex {
            flex-direction: row;
        }
    }
    .hero__strapline {
        display: flex;
        flex-direction: column;
        flex: 0 0 75%;
        margin-bottom: var(--base-padding);

        @media ${device.md} {
            margin-bottom: 0;
        }
    }
    .hero__title {
        font-size: 6.5rem;
        font-weight: 600;
        line-height: 8.125rem;
        text-transform: none;
        color: #ffffff;
        padding: 0;
        margin-bottom: 1rem;
    }
    @media (max-width: 1200px) {
        .hero__title {
            font-size: 4rem;
            line-height: 1;
        }
    }
    .hero__summary {
        font-size: 1.125rem;
        line-height: 1.5;
        color: white;
        font-weight: 100;
        display: flex;
        align-items: center;
        margin-left: var(--base-padding);
    }
    @media (max-width: 768px) {
        .hero__summary {
            font-size: 1.25rem;
            margin-left: 0;
        }
    }
    .hero__title span {
        display: block
    }
    .hero__subtitle {
        color: #ccc;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 4px;
        margin-bottom: var(--base-padding);
    }
    @media (max-width: 768px) {
        .hero__title span {
            color: #fff
        }
    }
    .hero__video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
        background-size: cover;
        background-color: var(--color-black);
    }
    svg {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 14%;
        z-index: -1;
        opacity: 0.1;

        @media ${device.md} {
            height: 50%;
        }
    }
`;