import React, { Fragment} from 'react';
import Helmet from "react-helmet";
import Hero from "../Hero"

const ThePRChallenge = ({dimensions}) => {

    return (
        <Fragment>
            <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>The PR Challenge - Vary Digital</title>
        <meta name="title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/about" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/about" data-react-helmet="true" />
      </Helmet>
        <Hero title="The PR Challenge" summary="" image="images/prchallenge-hero.png" />
        <section className="section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container ">
                <header className="section-header">
                    <span className="section-subtitle">Turn workouts into epic quests! Conquer records, achieve greatness.</span>
                    <h2 className="section-title">The PR Challenge</h2>
                </header>
                <p>The PR Challenge is the ultimate fitness app designed to make exercise engaging, fun, and rewarding. Track and beat your personal records in a variety of exercise challenges, from time-based repetitions to max weight achievements. Whether you're a beginner looking to improve your fitness or a seasoned athlete aiming for new heights, this app has got you covered.</p>
                <p>With The PR Challenge, you can log and monitor your progress in real-time, keeping you motivated and accountable. Set your own custom challenges, tailoring them to your unique preferences and goals. Push your limits with exercises like push-ups, pull-ups, sit-ups, planks, as well as traditional strength exercises like bench press, squat, and leg press.</p>
                <p>Achievements are at the heart of The PR Challenge. Earn medals, trophies, and badges as you surpass personal milestones and conquer new records. Celebrate your accomplishments and share them with friends and the fitness community to inspire and challenge others.</p>
                <p>The app features a user-friendly interface that makes it easy to navigate and track your workouts. Set reminders, create personalized workout plans, and access comprehensive exercise tutorials and tips to ensure proper form and technique. Stay motivated with progress charts, performance analytics, and friendly competition with other app users.</p>
                <p>Unlock your full potential and transform exercise from a chore into an exciting game with The PR Challenge. Experience the thrill of beating your own records, improving your fitness, and achieving new levels of strength and endurance. Get ready to embark on a fitness journey like no other and become the best version of yourself.</p>
                <p>Download The PR Challenge now and let the games begin!</p>
                <h3>Feedback and feature requests</h3>
                <p>We welcome all feedback and feature requests, if you have any questions or feature requests, please contact us at <a href="mailto:info@varydigital.com">info@varydigital.com</a>.</p>
            </div>
        </section>
        
      </Fragment>
    )
}

export default ThePRChallenge;