import React, { Fragment } from 'react';
import Helmet from "react-helmet";
import Hero from "../Hero"

const Service = ({dimensions}) => {

    return (
    <Fragment>
        <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Discover the digital services we provide - Vary Digital</title>
        <meta name="title" content="Discover the digital services we provide - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="We provide a variety of digital services including Website User Experience Design, PPC, Social Media, SEO, Video Production and more." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Discover the digital services we provide - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="We provide a variety of digital services including Website User Experience Design, PPC, Social Media, SEO, Video Production and more." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-services.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/services" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="Discover the digital services we provide - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="We provide a variety of digital services including Website User Experience Design, PPC, Social Media, SEO, Video Production and more." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-services.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="Discover the digital services we provide - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="We provide a variety of digital services including Website User Experience Design, PPC, Social Media, SEO, Video Production and more." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-services.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/services" data-react-helmet="true" />
      </Helmet>
        <Hero title="What we offer" summary="Digital services that provide your business value and results" image="images/services-hero.webp" />
        <section className="section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                <div>
                    <header className="section-header">
                          <span className="section-subtitle">UI &amp; UX Design</span>
                          <h2 className="section-title">User Interface &amp; User Experience Design.</h2>
                      </header>
                      <p>We believe no website project should be built without your customers in mind. That's why we perform sizeable research into your customers and look for the content they are after and making that user journey for your customers a enjoyable experience and delivering that key information your customers are looking for (front and center) with ease.</p>
                        <p>We also look into who your direct and indirect compeitors are and we identify what they are doing that you should.</p>
                        <p>We dive into your customer data and investigate your compeitors to develop a strategy that will keep your customers happy and you even happier.</p>
                        <a className="link" href="/contact">Find out more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg></a>
                </div>
                <div>
                    <h3>User Research &amp; Competitor Analysis</h3>
                    <p>We provide all services to manage all aspects of your company's digital online presence from PPC, Social Media, Video Editing, Copywriting, SEO and more.</p>
                    <h3>Information Architecture &amp; Sitemap</h3>
                    <p>We offer monthly reports on all your digital services you have taken out with us, so you can see how well your website, posts or ads are performing.</p>
                    <h3>Wireframing</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                    <h3>Branding &amp; Identity</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                  </div>
                </div>
            </div>
        </section>
        <section className="section--padding background--grey">
            <div className="angle-top" style={{borderRightColor: '#eee', borderRightWidth: dimensions.width}}></div>
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                <div>
                    <header className="section-header">
                          <span className="section-subtitle">Technical web development</span>
                          <h2 className="section-title">WordPress Development</h2>
                      </header>
                      <p>We believe no website project should be built without your customers in mind. That's why we perform sizeable research into your customers and look for the content they are after and making that user journey for your customers a enjoyable experience and delivering that key information your customers are looking for (front and center) with ease.</p>
                        <p>We also look into who your direct and indirect compeitors are and we identify what they are doing that you should.</p>
                        <p>All our digital projects are completely secure and built for growth using the most popular content management system.</p>
                        <a className="link" href="/contact">Find out more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg></a>
                </div>
                <div>
                    <h3>Growth &amp; Maintenance</h3>
                    <p>We offer a vast amount of maintenance packages which will grow your website.</p>
                    <h3>Assecssiblity</h3>
                    <p>We offer monthly reports on all your digital services you have taken out with us, so you can see how well your website, posts or ads are performing.</p>
                    <h3>Performance</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                    <h3>E-commerce</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                  </div>
                </div>
            </div>
        </section>
        <section className="section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                <div>
                    <header className="section-header">
                          <span className="section-subtitle">Marketing</span>
                          <h2 className="section-title">Digital Marketing</h2>
                      </header>
                      <p>We believe no website project should be built without your customers in mind. That's why we perform sizeable research into your customers and look for the content they are after and making that user journey for your customers a enjoyable experience and delivering that key information your customers are looking for (front and center) with ease.</p>
                        <p>We also look into who your direct and indirect compeitors are and we identify what they are doing that you should.</p>
                        <a className="link" href="/contact">Find out more <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg></a>
                </div>
                <div>
                    <h3>Email Marketing</h3>
                    <p>We offer a vast amount of maintenance packages which will grow your website.</p>
                    
                    <h3>Pay-per-click advertising</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                    <h3>Social Media</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                    <h3>Video production</h3>
                    <p>We produce your company it's own brand awarness and deliver your passion through digital techinques and back them up with results.</p>
                  </div>
                </div>
            </div>
        </section>
    </Fragment>
)
    }

export default Service;