import React, { Fragment} from 'react';
import Helmet from "react-helmet";
import Hero from "../Hero"

const About = ({dimensions}) => {

    return (
        <Fragment>
            <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital</title>
        <meta name="title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/about" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/about" data-react-helmet="true" />
      </Helmet>
        <Hero title="Who we are" summary="We are Vary Digital a Creative Digital Marketing Agency who specialise in WordPress and WooCommerce websites." image="images/about-hero.webp" />
        <section className="section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                <div>
                    <header className="section-header">
                          <span className="section-subtitle">About Vary Digital</span>
                          <h2 className="section-title">We thrive in producing our clients passion into an online digital experience for their customers.</h2>
                      </header>
                      <p>At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers. We only agree to projects that we are 100% confident in producing a high quality end product. No matter the size of your business we have a team of technical and creative specialists with the expertise that produces a product with great value and the results to show it.</p>
                      <p>We are based in Horsham (Sussex) but our clients are based all over the world. We deliver a product that gives your customers a great user experience and user journey.</p>
                </div>
                <div>
                    <h3>Full-Service Marketing Agency.</h3>
                    <p>We provide a variety of services to manage all aspects of your company's digital online presence.</p>
                    <h3>Fully Transparent &amp; Educational.</h3>
                    <p>We offer monthly reports on all your digital services you have taken out with us, so you can see how well your website, posts or ads are performing.</p>
                    <h3>Delivering Value &amp; Results.</h3>
                    <p>We produce your company it's own brand and deliver your passion in your industry through digital techinques.</p>
                    <h3>Client Partnerships.</h3>
                    <p>We believe in building long-term partnerships with our clients that continue to grow beyond the initial launch.</p>
                  </div>
                </div>
            </div>
        </section>
        <section className="services section--padding background--black">
            <div className="container">
                <header className="section-header">
                          <span className="section-subtitle">Our Values</span>
                          <h2 className="section-title">What makes us, us!</h2>
                      </header>
                <div className="grid grid--three grid-gap-4">
                    <div className="service-item">
                    <h3>Being honest and transparent.</h3>
                    <p>We don't make promises or agree to deadlines that we can't guarantee we can deliver on. We pride ourselves on achieving your goals with absolute certainity.</p>
                    </div>
                    <div className="service-item">
                    <h3>No hidden costs.</h3>
                    <p>We have no hidden costs and our maintenance packages outline exactly where your money is going so there are no surprises.</p>
                    </div>
                    <div className="service-item">
                      <h3>We value your success.</h3>
                      <p>Your success is our priority and we want you to be at the top.</p>
                    </div>
                    <div className="service-item">
                      <h3>No egos. No technical jargon.</h3>
                      <p>No need to know the lingo, we are clear about what's entailed in every piece of work. </p>
                    </div>
                    <div className="service-item">
                    <h3>Manage your control</h3>
                    <p>Choose how much control you have in your content management system.</p>
                    </div>
                    <div className="service-item">
                    <h3>Quality over quanity.</h3>
                    <p>We take pride in what we do and would rather a project is produced at the highest quality rather than produced quickly.</p>
                    </div>
                  </div>
            </div>
          </section>
        <section className="section--padding background--white">
            <div className="container">
                <div>
                    <header className="section-header">
                        <span className="section-subtitle">Key Partnerships</span>
                        <h2 className="section-title">We are Certified Professionals</h2>
                        <p className="section-summary">Our team of technical gurus and design wizards stay at the forefront, whether that is keeping up to date with new techincal releases or keeping our brands on the latest trend or fulfilling customer engagement. We continue learning and deliever the latest methods to every project.</p>
                    </header>
                    <div className="grid grid--certifications">
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/interaction-design.svg" alt="Interaction Design" />
                            <p><strong>Interaction Design - UX Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/kentico-certified-developer.png" alt="Kentico Certified Developers" />
                            <p><strong>Kentico Certified Developer</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/google-adwords-certified.png" alt="Google AdWords Certified" />
                            <p><strong>Google AdWords Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/google-analytics-certified.png" alt="Google Analytics Certified" />
                            <p><strong>Google Analytics Certified</strong></p>
                        </div>
                        <div className="certificate">
                            <img className="image-contain" src="images/awards/ucommerce.svg" alt="Ucommerce Masterclass" />
                            <p><strong>Ucommerce Masterclass</strong></p>
                        </div>
                    </div>
                </div>
          </div>
      </section>
      </Fragment>
    )
}

export default About;