import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import { Link } from 'react-router-dom';

const Menu = ({ open, setOpen }) => {

  function handleClick() {
    setOpen(!open);
    if(!open){
      document.body.classList.add('menu-open');
    }else {
      document.body.classList.remove('menu-open');
    }
  }

  return (
    <StyledMenu open={open}>
      <ul className="nav__mobile">
      <li><Link to='/' onClick={handleClick}>Home</Link><span>The overview of Vary Digital.</span></li>
      <li><Link to='/about' onClick={handleClick}>About</Link><span>Discover more about us.</span></li>
      <li><Link to='/websites' onClick={handleClick}>Projects</Link><span>Take a look at the projects we've produced.</span></li>
        <li><Link to='/services' onClick={handleClick}>Services</Link><span>Find out what services we provide.</span></li>
        <li><Link to='/contact' onClick={handleClick}>Get in touch</Link><span>Want to discuss a project?</span></li>
      </ul>
      <footer>
        <div>
          <a href="mailto:info@varydigital.com">info@varydigital.com</a>
        </div>
      </footer>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;