import React, { useState, useEffect } from 'react';
import Helmet from "react-helmet";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';

//import { Burger, Menu } from './components';
import MainNavigation from "./components/MainNavigation"
import Burger from "./components/Burger"
import Menu from "./components/Menu"
import Video from "./components/Video"
import Website from "./components/Websites"
import Services from "./components/Services"
import Contact from "./components/Contact"
import PrivacyPolicy from "./components/PrivacyPolicy"
import ThePRChallenge from "./components/ThePRChallenge"
import CTA from "./components/CTA"
import { recommendations } from './components/Recommendations/data'
import About from "./components/About"

import NotFound from "./components/NotFound"

import { websites } from './components/Websites/data';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Pagination,
  Navigation
} from "swiper/core";

import ScrollToTop from "./ScrollToTop"

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import './app.scss';

import ReactGA from 'react-ga';
ReactGA.initialize('G-YG953X2JN7');
ReactGA.pageview(window.location.pathname + window.location.search);

SwiperCore.use([Pagination,Navigation]);

function App() {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth
  })

  function handleResize() {
    setDimensions({
      width: window.innerWidth
    })
  }

  function handleScroll() {
    if (window.pageYOffset > 100) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  function backtoTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  useEffect(() => {
    
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <div>
    <Router>
    <ThemeProvider theme={theme}>
    <>
      
      <GlobalStyles />
      <ScrollToTop />
      <header className={`header ${scroll ? "bg-black" : ""}`}>
        <div className="container container--flex">
          <div className="logo">
            <a href="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="131.08" height="29.28" viewBox="0 0 131.08 29.28" fill="000000">
              <title>Vary Digital - Digital Specialists</title>
              <path id="Path_19" data-name="Path 19" d="M22.4-26.76l-8,17.04L6.32-26.76C5.84-27.72,5.48-28,4.56-28H.76c-.6,0-.88.2-.88.56a2.7,2.7,0,0,0,.2.68L12.6-1.36c.76,1.56,1.16,2,1.68,2,.6,0,.96-.4,1.72-2l12.44-25.4a2.7,2.7,0,0,0,.2-.68c0-.36-.28-.56-.88-.56H24.2C23.24-28,22.88-27.72,22.4-26.76ZM38.84-1.24l8-17.04L54.92-1.24C55.4-.24,55.76,0,56.68,0h3.8c.6,0,.88-.2.88-.56a2.7,2.7,0,0,0-.2-.68L48.64-26.64c-.8-1.6-1.08-2-1.68-2s-.96.4-1.72,2L32.8-1.24a2.7,2.7,0,0,0-.2.68c0,.36.28.56.88.56h3.56C38,0,38.36-.24,38.84-1.24ZM77.48,0c.68,0,1-.28,1-.96V-22.48c0-.44.16-.52.52-.52h3.12c4.12,0,6.32,1.4,6.32,4.56,0,2.92-2.24,4.52-5.6,4.88-.48.04-.72.16-.72.44a1.166,1.166,0,0,0,.24.6L89.2-1.16A1.869,1.869,0,0,0,91.16,0h3.88Q96,0,96-.48a2.026,2.026,0,0,0-.32-.76L89.2-11.52a7.537,7.537,0,0,0,5.08-7.4C94.28-25,90.36-28,82.32-28H73.84c-.68,0-.96.28-.96.96V-.96c0,.68.28.96.96.96Zm47-26.96-6.4,11.12-6.32-11.12A1.676,1.676,0,0,0,110-28h-4.12c-.52,0-.8.16-.8.48a1.19,1.19,0,0,0,.2.6l10,16.68V-.96c0,.68.28.96.96.96h3.64c.68,0,1-.28,1-.96v-9.48l9.88-16.48a1.19,1.19,0,0,0,.2-.6c0-.32-.24-.48-.76-.48h-3.96A1.648,1.648,0,0,0,124.48-26.96Z" transform="translate(0.12 28.64)" fill="#fff"/>
            </svg>
          </a>
          </div>
          <Burger open={open} setOpen={setOpen} />
          <MainNavigation />
        </div>
      </header>
      <Menu open={open} setOpen={setOpen} />
      <Switch>
          <Route exact path="/about">
            <About  dimensions={dimensions} />
          </Route>
          <Route exact path="/websites">
            <Website dimensions={dimensions} />
          </Route>
          <Route exact path="/services">
            <Services dimensions={dimensions} />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy dimensions={dimensions} />
          </Route>
          <Route exact path="/the-pr-challenge">
            <ThePRChallenge dimensions={dimensions} />
          </Route>
          <Route exact path="/">
          <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>Vary Digital - Specialist WordPress &amp; WooCommerce Digital Agency</title>
        <meta name="title" content="Vary Digital - Specialist WordPress &amp; WooCommerce Digital Agency" data-react-helmet="true" />
        <meta name="description" content="We are Vary Digital a UK WordPress &amp; WooCommerce Digital Agency that specialise in User Experience Design, Website Development and more, based in Horsham." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Vary Digital - Specialist WordPress &amp; WooCommerce Digital Agency" data-react-helmet="true" />
        <meta itemprop="description" content="We are Vary Digital a UK WordPress &amp; WooCommerce Digital Agency that specialise in User Experience Design, Website Development and more, based in Horsham." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="Vary Digital - Specialist WordPress &amp; WooCommerce Digital Agency" data-react-helmet="true" />
        <meta property="og:description" content="We are Vary Digital a UK WordPress &amp; WooCommerce Digital Agency that specialise in User Experience Design, Website Development and more, based in Horsham." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="Vary Digital - Specialist WordPress &amp; WooCommerce Digital Agency" data-react-helmet="true" />
        <meta name="twitter:description" content="We are Vary Digital a UK WordPress &amp; WooCommerce Digital Agency that specialise in User Experience Design, Website Development and more, based in Horsham." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com" data-react-helmet="true" />
      </Helmet>
          <Video 
          mp4="videos/smoke.mp4"
          webm="videos/smoke.webm"
          title="Powering your 
          online presence."
          subtitle="A Creative Digital Agency"
          summary="We proudly produce exceptional, high quality, brand and website solutions morphed by customer data and powered by WordPress that delivers value and success for our clients."
            />
            <section className="about section--padding">
            <div className="angle-top" style={{borderRightColor: '#eee', borderRightWidth: dimensions.width - 30}}></div>
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                  <div>
                      <header className="section-header">
                          <span className="section-subtitle">About Vary Digital</span>
                          <h2 className="section-title">Your online presence powered by data.</h2>
                      </header>
                      <p>We are a start-up experienced digital marketing agency specialising in the creation of website solutions on the WordPress platform. Our team have been developing, designing and maintaining exceptional digital user experiences from start-ups to small and medium-sized enterprises for 12 years.</p>
                      <p>We pride ourselves on achieving your goals and do so by putting your customers first, a happy customer equals a happy client.</p>
                      <a className="link" href="/about">About the Agency <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></a>
                  </div>
                  <div>
                    <h3>Full-Service Marketing Agency.</h3>
                    <p>We provide a variety of services to manage all aspects of your company's digital online presence.</p>
                    <h3>Fully Transparent &amp; Educational.</h3>
                    <p>We offer monthly reports on all your digital services you have taken out with us, so you can see how well your website, posts or ads are performing.</p>
                    <h3>Delivering Value &amp; Results.</h3>
                    <p>We produce your company it's own brand and deliver your passion in your industry through digital techinques.</p>
                  </div>
                </div>
            </div>
          </section>
          <section className="intro section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width - 30}}></div>
            <div className="container container--flex">
                <header className="section-header">
                    <span className="section-subtitle">A Team of Digital Specialists</span>
                    <div className="section-header-row">
                    <h2 className="section-title">Specialising in User Experience for WordPress websites.</h2>
                    <span className="section-summary">
                    Our projects are fueled by customer data and the results speak for themselves.<br /><br />

A happy customer = A happy client.
                    </span>
                    </div>
                </header>
            </div>
          </section>
          <section className="section latest--home">
              <div className="container container--full">
          <div className="grid grid--websites">
              {websites.filter(website => website.home === true).map((website, websiteIndex) => {

                  return (
                      <div className={`card card--website card--large`} key={websiteIndex}>
                          {(website.video ?
                              (<video autoPlay loop muted className="hero__video" poster="">
                                  <source src={`videos/${website.codename}.webm`} type="video/webm" />
                                  <source src={`videos/${website.codename}.mp4`} type="video/mp4" />
                              </video>)
                              :
                              (<img src={`/images/websites/${website.codename}.webp`} className={website.screenshot} alt={website.name} />)
                          )}
                          <div className="website__content">
                              <span className="website__name">{website.name}</span>
                              <h2 className="website__subtitle">{website.subtitle}</h2>
                              <span className="website__summary">{website.summary}</span>
                              <span className="card__arrow"></span>
                          </div>
                      </div>
                  )
              })}
          </div>
          </div>
          </section>

          <section className="services section--padding background--black">
            <div className="container container--flex">
                <div className="grid grid--two grid-gap-8">
                  <div className="service-intro">
                      <header className="section-header">
                          <span className="section-subtitle">Services Provided by Vary Digital</span>
                          <h2 className="section-title">Digital services that provide your business value and results</h2>
                      </header>
                      <p>We provide a variety of digital services through various techinques  to cover all aspects of your company's needs. Whether you're starting from scratch, looking to expand, optimise or upgrade your digital platform.</p>
                      <a className="link" href="/services">See our Services <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg></a>
                  </div>
                  <div>
                      <h3>User Interface &amp; User Experience Design.</h3>
                      <p>Before we've even started the design process we investigate how we can provide the best user experience for your customers.</p>
                    <h3>WordPress Development.</h3>
                    <p>All our digital projects are completely secure and built for growth using the most popular content management system.</p>
                    <h3>Digital Marketing.</h3>
                    <p>We provide multiple marketing services from PPC, Social Media, Email Marketing, Organic Traffic Generation and more.</p>
                  </div>
                </div>
            </div>
          </section>


          <section className="reviews section section--padding">
            <div className="container">
                <header className="section-header">
                    <h2 className="section-title center-text">Hear what our clients say about us</h2>
                    <span className="section-summary center-text">We value our clients feedback.</span>
                </header>
                <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{"clickable": true}} navigation={true} className="mySwiper" breakpoints={{
                  "768": {
                    "slidesPerView": 3
                  }
                }}>
                    {recommendations.map((recommendation, recommendationIndex) => {
                      return (
                        <SwiperSlide key={recommendationIndex}>
                          <div className="card card--recommendation">
                              <span className="card__recommendation">
                                <span className={`card__stars card__${recommendation.stars}-star`}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                </span>
                                "{recommendation.recommendation}"
                              </span>
                              <div className="card__profile">
                                  <div className="card__info">
                                      <span className="card__name">{recommendation.name}</span>
                                      <span className="card__title">{recommendation.title}</span>
                                      <span className="card__company">{recommendation.company}</span>
                                  </div>
                              </div>
                          </div>
                        </SwiperSlide>
                      )
                    })}
                </Swiper>
              </div>
          </section>
          <CTA title="Do you have a project you would like to discuss?" />
              </Route>
              <Route component={NotFound} />
          </Switch>
          <footer className="footer" role="contentinfo">
              <div className="container container--flex">
                <div className="footer__content">
                <div className="logo">
                <a href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="131.08" height="29.28" viewBox="0 0 131.08 29.28">
                    <title>Vary Digital - Digital Specialists</title>
                    <path id="Path_19" data-name="Path 19" d="M22.4-26.76l-8,17.04L6.32-26.76C5.84-27.72,5.48-28,4.56-28H.76c-.6,0-.88.2-.88.56a2.7,2.7,0,0,0,.2.68L12.6-1.36c.76,1.56,1.16,2,1.68,2,.6,0,.96-.4,1.72-2l12.44-25.4a2.7,2.7,0,0,0,.2-.68c0-.36-.28-.56-.88-.56H24.2C23.24-28,22.88-27.72,22.4-26.76ZM38.84-1.24l8-17.04L54.92-1.24C55.4-.24,55.76,0,56.68,0h3.8c.6,0,.88-.2.88-.56a2.7,2.7,0,0,0-.2-.68L48.64-26.64c-.8-1.6-1.08-2-1.68-2s-.96.4-1.72,2L32.8-1.24a2.7,2.7,0,0,0-.2.68c0,.36.28.56.88.56h3.56C38,0,38.36-.24,38.84-1.24ZM77.48,0c.68,0,1-.28,1-.96V-22.48c0-.44.16-.52.52-.52h3.12c4.12,0,6.32,1.4,6.32,4.56,0,2.92-2.24,4.52-5.6,4.88-.48.04-.72.16-.72.44a1.166,1.166,0,0,0,.24.6L89.2-1.16A1.869,1.869,0,0,0,91.16,0h3.88Q96,0,96-.48a2.026,2.026,0,0,0-.32-.76L89.2-11.52a7.537,7.537,0,0,0,5.08-7.4C94.28-25,90.36-28,82.32-28H73.84c-.68,0-.96.28-.96.96V-.96c0,.68.28.96.96.96Zm47-26.96-6.4,11.12-6.32-11.12A1.676,1.676,0,0,0,110-28h-4.12c-.52,0-.8.16-.8.48a1.19,1.19,0,0,0,.2.6l10,16.68V-.96c0,.68.28.96.96.96h3.64c.68,0,1-.28,1-.96v-9.48l9.88-16.48a1.19,1.19,0,0,0,.2-.6c0-.32-.24-.48-.76-.48h-3.96A1.648,1.648,0,0,0,124.48-26.96Z" transform="translate(0.12 28.64)" fill="#fff"/>
                  </svg>
                </a>
              </div>
                  <p>Powering your online presence.</p>
                  <p>Telephone: <a href="tel:07512564193">075125 64193</a>
                  Email: <a href="mailto:info@varydigital.com">info@varydigital.com</a></p>
                  <ul className="footer__socials">
                    <li><a href="https://www.linkedin.com/company/varydigital" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    <li><a href="https://www.instagram.com/varydigital/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                    <li><a href="https://www.facebook.com/varydigital" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                    <li><a href="https://twitter.com/VaryDigital" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                  </ul>
                </div>
                <div className="footer__content footer__content--right">
                  <ul className="footer__links">
                    <li><Link to='/about'>About us</Link></li>
                    <li><Link to='/websites'>Projects</Link></li>
                    <li><Link to='/services'>Services</Link></li>
                    <li><Link to='/contact'>Contact</Link></li>
                    <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                  </ul>
                  <a href="" onClick={backtoTop} className="backtotop">Back to top 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="icon bi bi-chevron-double-up" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708l6-6z"/>
                    <path fillRule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                  </svg></a>
                </div>
              </div>
            </footer>
        </>
        </ThemeProvider>
    </Router>
    </div>
    
  );
}

export default App;
