import styled from 'styled-components';


/*export const StyledBurger = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.5rem;
  height: 1.5rem;
  background: #3d3d3d;
  border: none;
  cursor: pointer;
  padding: 1rem;
  z-index: 10;
  
  &:focus {
    outline: none;
  }

  &:hover {
      cursor: pointer;
  }
  
  div {
    width: 1.5rem;
    height: 0.125rem;
    background: white;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
        transform: rotate(${({ open }) => open ? '27deg' : '0deg'})
    }

    &:last-child {
        transform: rotate(${({ open }) => open ? '-27deg' : '0deg'})
    }
  }*/


  export const StyledBurger = styled.a`
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: fixed;
    right: 1rem;
    top: 1rem;
    z-index: 9000;
    display: none;
    float: right;
    background: white;

    @media (max-width: 768px) {
      display: block;
    }

    &::before, &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: ${({ open }) => open ? '14px' : '12px'};
        top: 0;
        width: 16px;
        height: 2px;
        box-shadow: ${({ open }) => open ? '0 19px black, 0 19px black, 0 19px black, 0 19px black, 0 19px black, 0 19px black, 0 19px black' : '0 12px black,0 12px black,0 19px black,0 19px black,0 19px black,0 26px black,0 26px black'};
        transition: none;
    }

    &::before {
      transform: rotate(${({ open }) => open ? '-45deg' : '0'}) translate(${({ open }) => open ? '-15px,-7px' : '0'});
    }

    &::after {
      transform: rotate(${({ open }) => open ? '45deg' : '0'}) translate(${({ open }) => open ? '12px,-4px' : '0'});
    }

`;