const size = {
    sm: '480px',
    md: '768px',
    lg: '60em',
    xl: '90em'
}

export const device = {
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`
}