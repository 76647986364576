import styled from 'styled-components';
import { device } from '../Breakpoints';

export const StyledCTA = styled.section`
    svg {
        position: absolute;
        bottom: -27%;
        left: 0;
        right: 0;
        width: 100%;
        height: 90%;
        z-index: -1;
        opacity: 0.03;

        @media ${device.md} {
            
        }
    }
`;