export const websites = [
    {
        name: "Bolnore Heating Services",
        codename: "bolnoreheatingservices",
        subtitle: "Plumbers and Gas Engineers",
        summary: "Qualified plumbers and registered gas engineers.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "The Cock Inn",
        codename: "cockinn",
        subtitle: "A Warming Countryside Pub",
        summary: "Countryside pub with a glorious garden and a welcoming team.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "Somebody Listening",
        codename: "somebodylistening",
        subtitle: "Tackling Loneliness",
        summary: "An area for people to tell their stories anonymously.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "MyBalance",
        codename: "mybalance",
        subtitle: "Finance Management Application",
        summary: "A finance management application to help keep on top of your outgoings.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "Jackal",
        codename: "jackal",
        subtitle: "Jackal Home Improvements",
        summary: "For all your home improvement needs.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    },
    {
        name: "Dental Engineers",
        codename: "dentalengineers",
        subtitle: "Supplying Dental Equipment",
        summary: "Dental industry experts, offering equipment repair, maintenance and installation.",
        video: false,
        graveyard: true,
        teambuild: false,
        home: true
    }
];

export const getFilteredData = (filter, data) => {
    if (!filter) {
        return data;
    }

    return data.filter(item =>
        item.name.toLowerCase().includes(filter.toLowerCase())
    );
};