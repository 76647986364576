import React, { Fragment} from 'react';
import Helmet from "react-helmet";
import Hero from "../Hero"

const PrivacyPolicy = ({dimensions}) => {

    return (
        <Fragment>
            <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital</title>
        <meta name="title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta itemprop="description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta itemprop="image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.varydigital.com/about" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta property="og:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta property="og:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta property="og:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
        <meta name="twitter:title" content="WordPress &amp; WooCommerce Website Solutions built in the UK - Vary Digital" data-react-helmet="true" />
        <meta name="twitter:description" content="At Vary Digital, we love to build digital experiences that takes your idea and turn it into an enjoyable experience for you and your customers." data-react-helmet="true" />
        <meta name="twitter:image" content="https://www.varydigtial.com/images/og-card-about.jpg" data-react-helmet="true" />

        {/* <!-- Canonical --> */}
        <link rel="canonical" href="https://www.varydigital.com/about" data-react-helmet="true" />
      </Helmet>
        <Hero title="Privacy Policy" summary="We strive about being completely transparent" image="images/about-hero.webp" />
        <section className="section--padding background--white">
            <div className="angle-top" style={{borderRightColor: '#fff', borderRightWidth: dimensions.width}}></div>
            <div className="container ">
                <header className="section-header">
                    <span className="section-subtitle">Website Policy - Last Updated: 16/06/2023</span>
                    <h2 className="section-title">Vary Digital Privacy Policy</h2>
                </header>
                <h3>Vary Digital ("us", "we", or "our") operates the varydigital.com website (the "Service").</h3>
                <p>This page informs you of our policies regarding the collection, use, and disclosure of personal information we receive from users of the Service.</p>
                <h3>Information Collection and Use</h3>
                <p>The varydigital.com website may collect certain personally identifiable information, such as your name and email address, when you voluntarily submit it through our contact forms or other means. We use this information solely for the purpose of responding to your inquiries or providing you with requested information.</p>
                <h3>Cookies</h3>
                <p>The varydigital.com website may use cookies or similar technologies to enhance your browsing experience and collect information about how visitors use our website. These cookies may be used for analytics, personalized content, and targeted advertising. You can manage your cookie preferences or disable cookies through your browser settings.</p>
                <h3>Third-Party Services</h3>
                <p>We may use third-party services, such as Google Analytics, to track and analyze website traffic and usage patterns. These services may collect information about your use of the website, including your IP address, browser type, and referring pages. This information is used to improve our website and provide a better user experience. Please refer to the respective privacy policies of these third-party services for more information on how they handle your data.</p>
                <h3>Data Security</h3>
                <p>We implement reasonable security measures to protect the personal information we collect through the Service. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure and we cannot guarantee the absolute security of your data.</p>
                <h3>Changes to This Privacy Policy</h3>
                <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the updated policy on this page. It is your responsibility to review this privacy policy periodically for any changes.</p>
                <h3>Contact Us</h3>
                <p>If you have any questions or concerns about this privacy policy or the handling of your personal information, please contact us at <a href="mailto:info@varydigital.com">info@varydigital.com</a>.</p>
            </div>
        </section>
        <section className="services section--padding background--black">
            <div className="container">
                <header className="section-header">
                    <span className="section-subtitle">App Policy - Last Updated: 16/06/2023</span>
                    <h2 className="section-title">Vary Digital App Privacy Policy</h2>
                </header>
                <p>This privacy policy applies to the mobile applications developed by Vary Digital (referred to as "our apps").</p>
                <h3>Information Collection and Use</h3>
                <p>Our apps may collect anonymous usage data using third-party analytics services, such as Firebase Analytics or Google Analytics. This data is used to analyze app usage patterns, improve user experience, and enhance the functionality of our apps. The collected data may include information such as device type, operating system version, app usage statistics, and other analytics.</p>
                <h3>Data Storage</h3>
                <p>The data collected by our apps is stored locally on the user's device and is not transmitted or stored on our servers. We do not collect any personally identifiable information through our apps.</p>
                <h3>Third-Party Services</h3>
                <p>Our apps may integrate with third-party services, such as Firebase or other APIs, to provide specific features or functionalities. These services may have their own privacy policies and terms of service that govern the use of data collected through them. We encourage you to review the privacy policies of these third-party services for more information on how they handle your data.</p>
                <h3>Data Security</h3>
                <p>We take reasonable measures to protect the data collected by our apps, including the implementation of appropriate security technologies and measures. However, please note that no method of data transmission or storage is completely secure, and we cannot guarantee the absolute security of your data.</p>
                <h3>Changes to This Privacy Policy</h3>
                <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the updated policy on this page. It is your responsibility to review this privacy policy periodically for any changes.</p>
                <h3>Contact Us</h3>
                <p>If you have any questions or concerns about this privacy policy or the handling of your personal information, please contact us at <a href="mailto:info@varydigital.com">info@varydigital.com</a>.</p>
            </div>
          </section>
        
      </Fragment>
    )
}

export default PrivacyPolicy;