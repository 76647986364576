import React from 'react';
import { StyledVideo } from './Video.styled';

const Video = props => (
    <StyledVideo className="hero hero--campaign">
        <div className="container container--flex">
            <div className="hero__strapline">
                <span className="hero__subtitle">{props.subtitle}</span>
                <h1 className="hero__title">{props.title}</h1>
            </div>
            <span className="hero__summary">{props.summary}</span>
        </div>
        <video autoPlay loop muted className="hero__video" poster="">
            <source src={props.webm} type="video/webm" />
            <source src={props.mp4} type="video/mp4" />
        </video>
        <svg xmlns="http://www.w3.org/2000/svg" width="131.08" height="29.28" viewBox="0 0 131.08 29.28">
            <path id="Path_19" data-name="Path 19" d="M22.4-26.76l-8,17.04L6.32-26.76C5.84-27.72,5.48-28,4.56-28H.76c-.6,0-.88.2-.88.56a2.7,2.7,0,0,0,.2.68L12.6-1.36c.76,1.56,1.16,2,1.68,2,.6,0,.96-.4,1.72-2l12.44-25.4a2.7,2.7,0,0,0,.2-.68c0-.36-.28-.56-.88-.56H24.2C23.24-28,22.88-27.72,22.4-26.76ZM38.84-1.24l8-17.04L54.92-1.24C55.4-.24,55.76,0,56.68,0h3.8c.6,0,.88-.2.88-.56a2.7,2.7,0,0,0-.2-.68L48.64-26.64c-.8-1.6-1.08-2-1.68-2s-.96.4-1.72,2L32.8-1.24a2.7,2.7,0,0,0-.2.68c0,.36.28.56.88.56h3.56C38,0,38.36-.24,38.84-1.24ZM77.48,0c.68,0,1-.28,1-.96V-22.48c0-.44.16-.52.52-.52h3.12c4.12,0,6.32,1.4,6.32,4.56,0,2.92-2.24,4.52-5.6,4.88-.48.04-.72.16-.72.44a1.166,1.166,0,0,0,.24.6L89.2-1.16A1.869,1.869,0,0,0,91.16,0h3.88Q96,0,96-.48a2.026,2.026,0,0,0-.32-.76L89.2-11.52a7.537,7.537,0,0,0,5.08-7.4C94.28-25,90.36-28,82.32-28H73.84c-.68,0-.96.28-.96.96V-.96c0,.68.28.96.96.96Zm47-26.96-6.4,11.12-6.32-11.12A1.676,1.676,0,0,0,110-28h-4.12c-.52,0-.8.16-.8.48a1.19,1.19,0,0,0,.2.6l10,16.68V-.96c0,.68.28.96.96.96h3.64c.68,0,1-.28,1-.96v-9.48l9.88-16.48a1.19,1.19,0,0,0,.2-.6c0-.32-.24-.48-.76-.48h-3.96A1.648,1.648,0,0,0,124.48-26.96Z" transform="translate(0.12 28.64)" fill="#fff"/>
        </svg>
    </StyledVideo>
)

export default Video; 