import React from 'react';
import { StyledHero } from './Hero.styled';

const Hero = props => (
    <StyledHero className="hero hero--campaign">
     
                <div className="container container--flex">
                    <div className="hero__strapline">
                        <h1 className="hero__title">{props.title}</h1>
                        <span className="hero__summary">{props.summary}</span>
                    </div>
                </div>
            <div className="hero__background image-background">
                <img src={props.image} alt="" />
            </div>
    </StyledHero>
)

export default Hero; 